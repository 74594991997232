<template>
	<div class="pa-0 wrapper">
		<v-container
			class="pa-0"
			fluid
		>
			<!-- 타이틀 -->
			<v-col
				align="center"
				class="pa-0"
			>
				<v-row class="ma-0 py-5 justify-center main_tit">
					<v-col
						class="pa-0"
						cols="auto"
						align-self="center"
					>
						<v-img
							width="24"
							eager
							:src="require('@/assets/img/careflo/icon_care_flo.svg')"
						/>
					</v-col>
					<v-col
						class="pa-0 ml-1"
						align-self="center"
						cols="auto"
					>
						케어플로 서비스
					</v-col>
				</v-row>
			</v-col>

			<!-- 구매자정보 입력완료 -->
			<v-col
				cols="12"
				class="py-6 px-4 mb-5 section"
				align="center"
			>
				<v-img
					width="30"
					eager
					:src="require('@/assets/img/careflo/icon_clear.svg')"
					class="mb-4"
				/>
				<h3 class="mb-5">구매자 정보 입력 완료!</h3>
				<p class="desc">
					입력하신 이름과 휴대폰번호로 <br />
					구매자 인증 접속을 해보세요
				</p>
			</v-col>

			<!-- 인증 접속하기 -->
			<v-col class="pa-0 px-4">
				<CommonButtonsButton01
					class="mb-5 btn_clear"
					name="구매자 인증 접속하기"
					color="#78C046"
					height="42"
					@click="$router.push(`/careflo/customer/login`)"
				/>

				<div class="mb-15 center_info">
					<v-row class="ma-0 justify-center">
						<v-col
							class="pa-0"
							cols="auto"
							align="end"
						>
							<div class="mr-1 txt_1">고객센터</div>
						</v-col>
						<v-col
							class="pa-0"
							cols="auto"
						>
							<div class="ml-2">
								<v-col class="pa-0 txt_2">1899-2837</v-col>
								<v-col class="pa-0 txt_3">평일 9:00 ~ 17:00(공휴일 외)</v-col>
							</div>
						</v-col>
					</v-row>
				</div>
			</v-col>
		</v-container>
	</div>
</template>

<script>
import { mapMutations } from 'vuex'
import model from '@/mixins/model'
export default {
	name: 'HomeCareFloCustomerClear',
	mixins: [model],
	data: () => ({
		allow: {
			age: false,
			use_yn: false,
			info: false,
			sms: false,
			email: false,
		},
	}),
	computed: {
		allValue() {
			return Object.keys(this.allow).every(element => this.allow[element] === true)
		},
		allowValue() {
			let check = false
			const checkValue = ['age', 'use_yn', 'info']
			for (let i of checkValue) {
				if (this.allow[i] === false) check = true
			}
			return check
		},
	},
	watch: {},
	mounted() {},
	methods: {
		...mapMutations(['AUTH_MU_EVENT_INFO']),
		allowAll() {
			let val = true
			if (this.allValue) val = false

			Object.keys(this.allow).forEach(element => {
				this.allow[element] = val
			})
		},
		confirm() {
			const item = {
				sms: this.allow.sms,
				email: this.allow.email,
			}
			this.$cookies.set('signupAllowCookie', item)
			this.AUTH_MU_EVENT_INFO(item)
			this.model = 1
			this.$emit('submit')
		},
	},
}
</script>

<style scoped lang="scss">
.main_tit {
	font-size: $font_xl;
	font-weight: $fw_bold;
	line-height: 1;
	color: $color_font;
}
.sub_tit {
	font-size: $font_normal;
	color: $color_gray_7;
}

.section {
	background-color: $color_white;
	h3 {
		color: $color_font;
		font-size: $font_lg;
		font-weight: $fw_bold;
	}
	.desc {
		font-size: $font_normal;
		color: $color_gray_5;
	}
	.item {
		font-size: $font_normal;
		color: $color_gray_8;
	}
}

.btn_clear {
	:deep(.v-btn__content) {
		color: $color_white;
		font-weight: $fw_bold;
		font-size: $font_lg;
	}
}

.center_info {
	.txt_1 {
		font-size: $font_normal;
		color: $color_gray_6;
	}
	.txt_2 {
		font-size: $font_lg;
		font-weight: $fw_bold;
		color: $color_gray_8;
	}
	.txt_3 {
		font-size: $font_normal;
		color: $color_gray_8;
	}
}
</style>
